import { FunctionComponent, useCallback, useEffect, useState } from "react";
import Script from "next/script";
import { isFeatureEnabled } from "../lib/utils/is-feature-enabled";

const isReviewsEnabled = isFeatureEnabled(
  process.env.NEXT_PUBLIC_ENABLE_YOTPO_REVIEWS,
);

const reviewsId = "yotpo-reviews";

const yotpoAppKey = process.env.NEXT_PUBLIC_YOTPO_APP_KEY;

type YotpoReviewsLinkProps = {
  productId: string;
};

const useReviewsMinimum = (productId: string) => {
  const REVIEWS_LIMIT = 10;

  const [shouldRenderReviews, setShouldRenderReviews] =
    useState<boolean>(false);

  async function fetchReviewsTotal() {
    const reviewsResponse = await fetch(
      `https://api-cdn.yotpo.com/products/${yotpoAppKey}/${productId}/bottomline`,
    );
    const reviewsJson = await reviewsResponse?.json();
    return reviewsJson?.response?.bottomline?.total_reviews;
  }

  useEffect(() => {
    fetchReviewsTotal().then((reviewsTotal) => {
      if (reviewsTotal >= REVIEWS_LIMIT) {
        setShouldRenderReviews(true);
      }
    });
  }, []);

  return shouldRenderReviews;
};

export const YotpoReviewsLink: FunctionComponent<YotpoReviewsLinkProps> = ({
  productId,
}) => {
  const exceedsMinimumReviews = useReviewsMinimum(productId);
  const shouldRender = isReviewsEnabled && exceedsMinimumReviews;

  const loadWidgets = useCallback((node: HTMLAnchorElement) => {
    if (node !== null) {
      window.yotpoWidgetsContainer?.initWidgets?.();
    }
  }, []);

  return (
    shouldRender && (
      <a
        className="yotpo-star-reviews-widget"
        data-test-id="yotpo-reviews-link"
        href={`#${reviewsId}`}
        key={productId}
        ref={loadWidgets}
      >
        <div
          className="yotpo-widget-instance"
          data-yotpo-instance-id="736797"
          data-yotpo-product-id={productId}
        />
      </a>
    )
  );
};

type YotpoReviewsProps = {
  productId: string;
  productName: string;
  productImageUrl: URL["pathname"];
  productUrl: URL["pathname"];
};

export const YotpoReviews: FunctionComponent<YotpoReviewsProps> = ({
  productId,
  productName,
  productImageUrl,
  productUrl,
}) => {
  const exceedsMinimumReviews = useReviewsMinimum(productId);
  const shouldRender = isReviewsEnabled && exceedsMinimumReviews;

  const loadWidgets = useCallback((node: HTMLElement) => {
    if (node !== null) {
      window.yotpoWidgetsContainer?.initWidgets?.();
    }
  }, []);

  return (
    shouldRender && (
      <section
        data-test-id="yotpo-reviews"
        id={reviewsId}
        key={productId}
        ref={loadWidgets}
      >
        <div
          className="yotpo-widget-instance"
          data-yotpo-instance-id="779048"
          data-yotpo-product-id={productId}
          data-yotpo-name={productName}
          data-yotpo-url={productUrl}
          data-yotpo-image-url={productImageUrl}
        />
      </section>
    )
  );
};

type YotpoConversionTrackingProps = {
  orderAmount: string;
  orderCurrency: string;
  orderId: string;
};

// https://support.yotpo.com/docs/conversion-tracking-overview#generic-installation-all-other-platforms
export const YotpoConversionTracking: FunctionComponent<
  YotpoConversionTrackingProps
> = ({ orderAmount, orderId, orderCurrency }) =>
  isReviewsEnabled && (
    <>
      <Script
        dangerouslySetInnerHTML={{
          __html: `(function e(){var e=document.createElement("script");e.type="text/javascript",e.async=true,e.src="//staticw2.yotpo.com/${yotpoAppKey}/widget.js";var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(e,t)})();`,
        }}
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `yotpoTrackConversionData = {orderId: "${orderId}", orderAmount: "${orderAmount}", orderCurrency: "${orderCurrency}"};`,
        }}
      />
      <noscript>
        <img
          alt=""
          src={`//api.yotpo.com/conversion_tracking.gif?app_key=${yotpoAppKey}&order_id=${orderId}&order_amount=${orderAmount}&order_currency=${orderCurrency}`}
          width="1"
          height="1"
        />
      </noscript>
    </>
  );

export const YotpoReviewsCarousel = () => {
  const loadWidgets = useCallback((node: HTMLElement) => {
    if (node !== null) {
      window.yotpoWidgetsContainer?.initWidgets?.();
    }
  }, []);

  return (
    <section>
      <div
        className="yotpo-widget-instance"
        data-yotpo-instance-id="905342"
        ref={loadWidgets}
      />
    </section>
  );
};
